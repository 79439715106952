<template>
  <b-card
    class="mb-1 p-0"
  >
    <b-card-body class="p-1 pl-2">
      <div
        v-b-toggle="'collapse-subject-' + index"
        class="lead collapse-title"
      >
        <div class="d-flex align-items-center">
          <div class="p-0 d-flex">
            <feather-icon
              icon="AlignJustifyIcon"
              size="16"
              class="text-body align-middle m-0 p-0 grade-handle mr-2"
            />
          </div>
          <span class="mr-auto">
            {{ grade.name }}
          </span>

          <div class="p-0">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              class="p-0"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle m-0 p-0"
                />
              </template>
              <b-dropdown-item @click.stop="$emit('addSubject',grade.id)">
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                <span>{{ $t('Add-New') }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click.stop="$emit('editGrade',index)">
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span>{{ $t('Edit') }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click.stop="$emit('deleteGrade',grade.id)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>{{ $t('delete') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </b-card-body>

    <!-- collapsable subject -->
    <b-collapse
      :id="`collapse-subject-` + index"
      :ref="`collapse-subject-` + index"
      class="mt-0 mb-1 px-1"
      :visible="index===0"
    >
      <b-list-group v-if="grade.subjects && grade.subjects.length>0">
        <draggable
          v-model="grade.subjects"
          :options="{animation:300}"
          handle=".subject-handle"
          @change="updateOrder('subject')"
        >
          <b-list-group-item
            v-for="(subject, sIndex) in grade.subjects"
            :key="sIndex"
            class="py-0 pr-0"
            style="cursor:pointer"
            :class="{ active: subject.id === activeSubject }"
            @click="$emit('subjectSelected',subject.id,grade.id)"
          >
            <div class="d-flex align-items-center">
              <div class="p-0 d-flex">
                <feather-icon
                  icon="AlignJustifyIcon"
                  size="16"
                  class="text-body align-middle m-0 p-0 subject-handle mr-2"
                />
              </div>
              <span class="mr-auto">
                {{ subject.name }}
              </span>
              <subject-detail
                :subject="subject"
                @editSubject="$emit('editSubject',sIndex,grade.id)"
                @deleteSubject="$emit('deleteSubject',subject.id)"
                @uploadDomain="showUploadDomainModal = true"
                @reOrderDomain="subjectToOrder = subject.id"
              />
            </div>
          </b-list-group-item>
        </draggable>
      </b-list-group>

      <b-alert
        variant="warning"
        :show="grade.subjects && grade.subjects.length==0"
      >
        <div class="alert-body">
          <span style="font-size:12px">{{ $t('standard-module.no-subjects') }}</span>
        </div>
      </b-alert>

    </b-collapse>
    <upload-domain
      :show="showUploadDomainModal"
      :subject-id="activeSubject"
      @close="showUploadDomainModal = false"
      @reFetch="$emit('reFetch')"
    />
    <ConfirmOrder
      :show="!!subjectToOrder"
      :on-delete="handleReorder"
      title="ReOrder"
      varient="primary"
      sub-title="Are you sure you want to reorder"
      :is-processing-delete="isReordering"
      @close="subjectToOrder = null"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody, BDropdown, BDropdownItem, VBToggle, BCollapse, BListGroupItem, BListGroup, BAlert,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import draggable from 'vuedraggable'
import ConfirmOrder from '@/views/common/components/DeleteModal.vue'
import eventBus from '@/utils/eventBus'
import SubjectDetail from '../subject/Show.vue'
import UploadDomain from './UploadDomain.vue'

export default {
  components: {
    UploadDomain,
    BCard,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BCollapse,
    BListGroupItem,
    BListGroup,
    SubjectDetail,
    BAlert,
    draggable,
    ConfirmOrder,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    grade: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    activeSubject: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isReordering: false,
      subjectToOrder: null,
      selectedSubjectId: null,
      showUploadDomainModal: false,
    }
  },
  methods: {
    handleReorder() {
      this.isReordering = true
      useJwt.orderStandardSubject(this.subjectToOrder).then(response => {
        this.showSuccessMessage(response)
        eventBus.$emit('refetchGecDomains')
        this.subjectToOrder = null
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isReordering = false
      })
    },
    updateOrder(type) {
      const input = this.grade.subjects.map((e, i) => ({ id: e.id, order_index: i + 1 }))
      useJwt.updateOrder({
        model: type,
        input,
      })
    },
  },
}
</script>

<style scoped>
.active {
  background-color: rgba(22,29,49,0.4);
}
*{
  user-select: none;
}
.card-body{
  padding: 0;
}
</style>
