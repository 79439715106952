<template>
  <!-- create  -->
  <b-modal
    id="modal-create-edit"
    ref="modal-create-edit"
    v-model="isVisible"
    ok-only
    size="lg"
    ok-variant="primary"
    :ok-title="$t('confirm')"
    modal-class="modal-primary"
    centered
    no-close-on-backdrop
    :title="$t(`standard-module.${mode}-standard`)"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-col md="12">
      <!-- form -->
      <b-form @submit.prevent="submitData">
        <validation-observer ref="standardModalRef">
          <b-row>
            <!-- name -->
            <b-col md="12">
              <b-form-group
                :label="$t('standard-module.name')"
                label-for="tem-name"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="name"
                  rules="required"
                  vid="name"
                >
                  <b-form-textarea
                    id="item_name"
                    v-model="localStandard.name"
                    name="item_name"
                    :state="errors.length > 0 ? false : null"
                    :rows="3"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                :label="$t('standard-module.code')"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('standard-module.code')"
                  rules="required"
                  vid="code"
                >
                  <b-form-input
                    v-model="localStandard.code"
                    :state="errors.length > 0 ? false : null"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- name -->
          </b-row>
          <MainPoints :main-points="localStandard.main_points" />
        </validation-observer>
      </b-form>
    </b-col>
    <template v-if="standard?.standard_families.length > 0">
      <b-col md="12"
             class="mb-1"
      >
        <h5>
          Associate Standards
        </h5>
        <span v-for="(associate, index) in standard.standard_families"
              :key="index"
              class="border rounded shadow-sm"
              style="padding: 4px; margin-right: 5px;"
        >
          {{ `${associate.children.name} - ${associate.children.code} ,` }}
        </span>
      </b-col>
    </template>
    <b-col v-if="standard"
           md="12"
    >
      <div class="d-flex">
        Add Associate Standard
        <b-form-checkbox v-model="associateToggle"
                         value="1"
                         unchecked-value="0"
                         switch
                         class="ml-1"
        />
      </div>
    </b-col>
    <template v-if="associateToggle === '1'">
      <b-col>
        <b-form-group
          label="Grade"
          label-for="tem-name"
        >
          <b-form-select
            v-model="selectedGradeId"
            @change="getSubjectForGrade"
          >
            <option
              v-for="grade of [{text: 'Select Grade', value: null}, ...grades]"
              :key="grade.id"
              :value="grade.id"
            >
              {{ grade.name }}
            </option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Subject"
          label-for="tem-name"
        >
          <b-form-select
            v-model="selectedSubjectId"
            :disabled="!selectedGradeId"
            @change="getStandardDomain"
          >
            <option
              v-for="subject of [{text: 'Select Subject', value: null}, ...subjectList]"
              :key="subject.id"
              :value="subject.id"
            >
              {{ subject.name }}
            </option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Domain"
          label-for="tem-name"
        >
          <b-form-select
            v-model="standardDomain"
            :disabled="!selectedSubjectId"
            @change="getStandard"
          >
            <option
              v-for="domain of [{text: 'Select Subject', value: null}, ...domainList]"
              :key="domain.id"
              :value="domain.id"
            >
              {{ domain.name }}
            </option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Standard"
          label-for="tem-name"
        >
          <v-select
            v-model="selectedStandard"
            :clearable="false"
            label="text"
            :options="standardList"
            :reduce="option => ({ value: option.value, text: option.text })"
            debounce="200"
            multiple
          />
        </b-form-group>
      </b-col>
    </template>

    <template #modal-footer="{ }">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            :disabled="isProcessing"
            @click="submitData()"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            {{ $t("actions." + mode) }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
  <!-- create  -->

</template>

<script>
import {
  BModal, BForm, BFormInput, BFormGroup, BButton, BSpinner, BRow, BCol, BFormTextarea, BFormSelect, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import MainPoints from './MainPoints.vue'

const initialStandard = () => ({
  name: '',
  code: '',
  main_points: {
    point_1: '',
    point_2: '',
    point_3: '',
  },
})

export default {
  components: {
    BModal,
    BForm,
    BFormTextarea,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BFormGroup,
    BRow,
    BButton,
    BSpinner,
    BFormSelect,
    BFormCheckbox,
    vSelect,
    MainPoints,
  },
  directives: {
    Ripple,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    standard: {
      type: Object,
      default: () => {},
    },
    gradeId: {
      type: Number,
      default: null,
    },
    domainId: {
      type: Number,
      default: null,
    },
    subjectId: {
      type: Number,
      default: null,
    },
    grades: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isProcessing: false,
      localStandard: {
        ...initialStandard(),
      },
      isVisible: this.show,
      associateToggle: 0,
      selectedGradeId: null,
      selectedSubjectId: null,
      standardDomain: null,
      selectedStandard: [],
      subjectList: [],
      domainList: [],
      standardList: [],

    }
  },
  computed: {
    mode() {
      return this.standard ? 'update' : 'create'
    },
  },
  watch: {
    show() {
      this.isVisible = this.show
      this.associateToggle = 0
      this.selectedGradeId = null
      this.selectedSubjectId = null
      this.standardDomain = null
      this.selectedStandard = this.standard?.standard_families.map(associate => ({
        text: associate.children.name,
        value: associate.children.id,
      }))
    },
    standard() {
      if (this.standard) this.localStandard = JSON.parse(JSON.stringify(this.standard))
      else this.localStandard = { ...initialStandard() }
    },
  },
  methods: {
    createStandard() {
      this.isProcessing = true
      useJwt.createStandard({
        ...{
          grade_id: this.gradeId,
          subject_id: this.subjectId,
          standard_domain_id: this.domainId,
        },
        ...this.localStandard,
      }).then(res => {
        this.showSuccessMessage(res)
        this.localStandard = { ...initialStandard() }
        this.$emit('standardCreated')
      }).catch(e => {
        if (e.response?.data?.errors) this.$refs.standardModalRef.setErrors(e.response.data.errors)
        else this.showErrorMessage(e)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    updateStandard() {
      this.isProcessing = true
      useJwt.updateStandard(this.localStandard.id, {
        ...{
          grade_id: this.gradeId,
          subject_id: this.subjectId,
          standard_domain_id: this.domainId,
          associate_standard: this.selectedStandard.map(item => item.value),
        },
        ...this.localStandard,
      }).then(res => {
        this.showSuccessMessage(res)
        this.$emit('standardUpdated')
      }).catch(e => {
        if (e.response?.data?.errors) this.$refs.standardModalRef.setErrors(e.response.data.errors)
        else this.showErrorMessage(e)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    submitData() {
      this.$refs.standardModalRef.validate().then(success => {
        if (success) {
          if (this.mode === 'create') this.createStandard()
          else this.updateStandard()
        }
      })
    },
    getSubjectForGrade() {
      this.domainList = []
      this.standardList = []
      const subject = this.grades.find(grade => grade.id === this.selectedGradeId)?.subjects || []
      this.subjectList = subject
    },
    getStandardDomain() {
      this.standardDomain = null
      this.domainList = []
      this.standardList = []
      useJwt
        .getDomainBySubject({ subject_id: this.selectedSubjectId })
        .then(response => {
          this.domainList = response?.data?.data
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    getStandard() {
      const standardList = this.domainList.find(domain => domain.id === this.standardDomain)?.standard || []
      this.standardList = standardList
        .filter(standard => standard.id !== this.standard.id) // Exclude the standard with id 1
        .map(standard => ({
          text: standard.name,
          value: standard.id,
        }))
    },
  },
}
</script>

<style>

</style>
